/* ==================================================
Mobile nav display toggle:

Toggles animated display of main header nav
on small screens
===================================================*/
$('.nav-toggle').click(function(e) {
    e.preventDefault();
    var $mainNav = $('.page-header .small-nav');
    var $navToggle = $(this);

    $navToggle.toggleClass('active');
    $mainNav.toggleClass('open');
    $navToggle.children('.hamburger').toggleClass('active');

    if($mainNav.hasClass('open')) {
        Foundation.Motion.animateIn($mainNav, 'hinge-in-top fast');
    } else {
        Foundation.Motion.animateOut($mainNav, 'hinge-out-top fast');
    }
});


/* ==================================================
Mobile Nav Dropdown List:

Toggles animated display of the next nav element
after clicking parent icon
===================================================*/

$('.dropdown .arrow-icon').on('click', function (e) {
  e.preventDefault();
  $(this).toggleClass('open');
  const trigger = $(this).parent().next('.list');

  const $toggleNext = new Foundation.Toggler(trigger, {
    animate: 'fade-in fade-out'
  });
  $(trigger).foundation('toggle');
});


/* ==================================================
Image comparison slider
===================================================*/

new Cocoen(document.querySelector('.cocoen'));


/* ==================================================
Ajax form submission
===================================================*/

$("#quote-request-form").submit(function(e) {
  e.preventDefault();

  var $form = $(this);
  $.post($form.attr("action"), $form.serialize()).then(function() {
    $('#quote-request-form-wrapper').addClass('hide');
    $('#quote-request-form-thanks').fadeIn();
  });
});

/* ==================================================
Smooth Scrolling
===================================================*/

// Select all links with hashes
$('a[href^="#"]:not(.accordion-title)').on('click',function (e) {
    e.preventDefault();

    var target = this.hash;
    var $target = $(target);

    $('html, body').stop().animate({
        'scrollTop': $target.offset().top
    }, 900, 'swing', function () {
        window.location.hash = target;
    });
});

/* ==================================================
Random testimonials
===================================================*/

// $.fn.random = function() {
//   return this.eq(Math.floor(Math.random() * this.length));
// }


// var $testimonials = $('.testimonial-text');
// $testimonials.addClass("hide");
// $testimonials.random().removeClass('hide');


$(document).ready(function(){
  $('.testimonial-text-slider').slick({
    arrows: false,
    dots: true
  });
});